@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Recoleta';
  src: url('../public/RecoletaAlt-Regular.eot');
  src: local('Recoleta Regular'), local('Recoleta-Regular'),
  url('../public/RecoletaAlt-Regular.eot?#iefix') format('embedded-opentype'),
  url('../public/RecoletaAlt-Regular.woff2') format('woff2'),
  url('../public/RecoletaAlt-Regular.woff') format('woff'),
  url('../public/RecoletaAlt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Antario';
  src: url('../public/Antario-Regular.eot');
  src: local('Antario'), local('Antario-Regular'),
  url('../public/Antario-Regular.eot?#iefix') format('embedded-opentype'),
  url('../public/Antario-Regular.woff2') format('woff2'),
  url('../public/Antario-Regular.woff') format('woff'),
  url('../public/Antario-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rise-shake {
  animation: jump-shaking 2s infinite;
}

@keyframes jump-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateY(-9px) }
  35% { transform: translateY(-9px) rotate(10deg) }
  55% { transform: translateY(-9px) rotate(-10deg) }
  65% { transform: translateY(-9px) rotate(10deg) }
  75% { transform: translateY(-9px) rotate(-10deg) }
  100% { transform: translateY(0) rotate(0) }
}
